<template>
  <v-row>
    <v-col cols="">
      <v-text-field v-model="addressSearch" :label="inputLabel" @keyup.enter="searchAddress"  outlined dense hide-details></v-text-field>
    </v-col>

    <v-col cols="auto" class="pl-0">
      <v-btn :loading="loading" class="mx-2" fab small color="primary" @click="searchAddress">
        <v-icon dark> mdi-magnify </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" v-if="!loading && addressSearchList.length">
      <div class="custom-control custom-radio mb-1" :class="resultsBg">
        <p class="mb-0">{{ selectLabel }}</p>
        <v-radio-group v-model="selectedAddress" class="mt-0">
          <v-radio
            v-for="a in addressSearchList.slice(0, resultLength)"
            :key="randomId + a.place_id"
            :value="a"
            :label="a.formatted_address"
          ></v-radio>
        </v-radio-group>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import getAddressList from '@/services/global/geolocation-google'

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    defaultValue: {
      type: String,
      default: null,
    },
    inputLabel: {
      type: String,
      default: 'Endereço Completo',
    },
    selectLabel: {
      type: String,
      default: 'Escolha o abaixo:',
    },
    resultLength: {
      type: Number,
      default: 5,
    },
    autoRun: {
      type: Boolean,
      default: false,
    },
    selectFirst: {
      type: Boolean,
      default: false,
    },
    resultsBg: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      addressSearchList: [],
      addressSearch: this.defaultValue,
      selectedAddress: null,
      randomId: null,
      loading: false,
    }
  },
  watch: {
    defaultValue(val) {
      if(val) this.setAddress(val);
    }
  },
  methods: {
    getAddressList,
    setAddress(address) {
      this.addressSearch = address
      this.searchAddress()
    },
    emit() {
      this.$nextTick(() => {
        this.$emit('input', this.selectedAddress)
      })
    },
    async searchAddress() {
      if (!this.addressSearch) return

      try {
        this.loading = true
        this.selectedAddress = null
        this.addressSearchList = []
        this.emit()
        let res = await this.getAddressList(this.addressSearch)
        this.addressSearchList = res.results
        if (this.selectFirst || this.addressSearchList.length === 1 || this.autoRun) {
          this.selectedAddress = this.addressSearchList[0]
          this.emit()
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    if (this.autoRun) {
      this.searchAddress()
    }
    this.randomId = Math.random()
      .toString(36)
      .substring(7)
  },
}
</script>

<style></style>
