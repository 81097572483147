<template>
  <div>
    <v-autocomplete
      item-value="id"
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      :filter="filter"
      :label="label"
      :chips="chips"
      :deletableChips="deletableChips"
      :outlined="outlined"
      :dense="dense"
      :rules="rules"
      :multiple="multiple"
      :auto-select-first="autoSelectFirst"
      hide-details
      hide-selected
    >
      <template v-slot:no-data>
        <p class="ma-4" v-if="isLoading">Carregando...</p>
        <p class="ma-4" v-else-if="!search">Digite para buscar</p>
        <p class="ma-4" v-else-if="search.length < 4">Digite pelo menos 4 caracteres</p>
        <p class="ma-4" v-else>Nenhum resultado encontrado</p>
      </template>
      <template v-slot:selection="data">
        <v-chip class="mt-1 mb-1" v-if="chips" :small="smallChips" :close="deletableChips" close-icon="mdi-close" @click:close="removeItem(data.item)">
          {{ data.item.name }}
        </v-chip>
        <span v-else>
          {{ data.item.name }}
        </span>
      </template>
      <template v-slot:item="data">
        {{ data.item.name }}
      </template>
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-row class="mt-2 mb-0">
          <v-col cols="12" class="text-center">
            <v-btn small color="primary" @click="$emit('client-modal')">Cadastrar Responsável</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
    <client-modal @success="selectClient" />
  </div>
</template>

<script>
import CLIENT from '@/services/client'
import ClientModal from '@/components/modals/ClientModal.vue'

export default {
  components: { ClientModal },
  props: {
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String | Number | Array,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      model: this.multiple ? [this.value] : `${this.value}`,
      search: null,
      isLoading: false,
      items: [],
      timeout: null,
    }
  },
  methods: {
    removeItem(item) {
      this.model = this.model.filter((i) => i !== item.id)
    },
    filter(obj, queryText) {
      const ltQueryText = queryText.toLowerCase()
      return (
        obj.id == queryText ||
        obj.name.toLowerCase().includes(ltQueryText) ||
        obj.email.toLowerCase().includes(ltQueryText)
      )
    },
    selectClient(client) {
      if (this.multiple) this.model = [...this.model, client.id]
      else this.model = client.id

      this.$emit('input', this.modal)
    },
    async s(val) {
      try {
        if (!val || val.length < 4 || this.isLoading) return

        this.isLoading = true
        const { clients } = await CLIENT.search(val)
        clients.forEach(client => {
          if (!this.items.find(item => item.id === client.id)) this.items.push(client)
        })
        if (this.selected) {
          this.selected = this.items.find(i => i.id == this.selected.id)
        }
      } catch (e) {
        this.$toast.fire({
          icon: 'error',
          title: e.message,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  watch: {
    value: {
      async handler(value) {
        this.model = value
        if (value) await this.s(value)
      },
      immediate: true,
    },
    model(value) {
      if (!value) this.$emit('input', null)
      this.$emit('input', value)
      this.search = null
    },
    async search(val) {
      if (!val || val.length < 4) return
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.s(val)
      }, 500)
    },
  },
  async mounted() {
    const value = this.value
    if (!value) return
    this.model = value
    await this.s(value, false)
  },
}
</script>

<style></style>
