import axios from "axios";
const { VUE_APP_MAPS_TOKEN } = process.env;

export default async address => {
  try {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?key=${VUE_APP_MAPS_TOKEN}&address=${encodeURI(address)}`
    );
    return data;
  } catch (e) {
    return false;
  }
};
