import http from '@/http-common'

const create = async data => {
  return await http
    .post('/client', data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const update = async (id, data) => {
  return await http
    .put(`/client/${id}`, data)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const getAll = async () => {
  return await http
    .get('/client')
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const search = async query => {
  return await http
    .get('/client/search', {
      params: {
        s: query,
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}
const attachmentUpload = async (clientId, data) => {
  var formData = new FormData()
  formData.append('file', data.file)
  formData.append('name', data.name)
  return http
    .post(`/client/${clientId}/attachment`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}

const attachmentDestroy = async (clientId, attachmentId) => {
  return await http
    .delete(`/client/${clientId}/attachment/${attachmentId}`)
    .then(r => r.data)
    .catch(error => {
      throw error.response?.data || error.response || error
    })
}


export default {
  create,
  update,
  search,
  getAll,
  attachment: {
    upload: attachmentUpload,
    destroy: attachmentDestroy,
  }
}
